import moment from 'moment';


export const LoginHelper = () => {
    /*
    {"date_login":"2021-05-10T06:46:38.501Z","token_type":"Bearer","token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjI0Y2EyZTNlLTQ4NWYtNGI5NC05ODNmLWIxOThkNDg3YmVjZCIsImVtYWlsIjoib3B0aWNhYmVucGx1c0BnbWFpbC5jb20iLCJpYXQiOjE2MjA2MjkxOTgsImV4cCI6MTYyMDcxNTU5OH0.wsM5srwQiq8g-ahkP0EojmLwZWkV7rXqj2J2Vp5X1sM","expires_in":"86400"}
    */
    /* console.log("entró al helper"); */
}

export const userSession = () => {
  return localStorage.getItem("user-info") ? JSON.parse(localStorage.getItem("user-info")) : null;
}
