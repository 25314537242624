import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {LoginHelper, userSession} from '../helpers/loginHelper';

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper"> {LoginHelper()}
      <Route path={`${match.url}login`} component={asyncComponent(() => import('./UserPage/LoginPage'))}/>
      <Route path={`${match.url}user/recovery`} component={asyncComponent(() => import('./UserPage/RecoveryPage'))}/>
      <Route path={`${match.url}user/reset-pass`} component={asyncComponent(() => import('./UserPage/RecoveryPage/resetPass'))}/>
      { userSession() ? (
        <div>
          { userSession().user.rol === 'ADMIN' ?
            <div>
              <Route path={`${match.url}users`} component={asyncComponent(() => import('./UserPage'))}/>
              <Route path={`${match.url}user/create`} component={asyncComponent(() => import('./UserPage/addUser'))}/>
              <Route path={`${match.url}user/edit/:id`} component={asyncComponent(() => import('./UserPage/addUser'))}/>
              <Route path={`${match.url}user/detail/:id`} component={asyncComponent(() => import('./UserPage/detailUser'))}/>
            </div>
          : <Redirect to="/" /> }
          <Route path={`${match.url}inventary/list`} component={asyncComponent(() => import('./InventaryPage'))}/>
          <Route path={`${match.url}inventary/product/create`} component={asyncComponent(() => import('./InventaryPage/ProductPage/addProduct'))}/>
          <Route path={`${match.url}inventary/product/edit/:id`} component={asyncComponent(() => import('./InventaryPage/ProductPage/addProduct'))}/>
          <Route path={`${match.url}inventary/product/detail/:id`} component={asyncComponent(() => import('./InventaryPage/ProductPage/detailProduct'))}/>
          <Route path={`${match.url}inventary/brand/list`} component={asyncComponent(() => import('./InventaryPage/BrandPage'))}/>
          <Route path={`${match.url}inventary/brand/create`} component={asyncComponent(() => import('./InventaryPage/BrandPage/addBrand'))}/>
          <Route path={`${match.url}inventary/brand/edit/:id`} component={asyncComponent(() => import('./InventaryPage/BrandPage/addBrand'))}/>
          <Route path={`${match.url}inventary/store/product/list`} component={asyncComponent(() => import('./InventaryPage/InventaryStorePage'))}/>
          <Route path={`${match.url}inventary/store/product/create`} component={asyncComponent(() => import('./InventaryPage/InventaryStorePage/addProductStore'))}/>
          <Route path={`${match.url}inventary/branch/product/create`} component={asyncComponent(() => import('./InventaryPage/InventaryStorePage/addProductBranch'))}/>
          <Route path={`${match.url}inventary/warehouse/view/:id`} component={asyncComponent(() => import('./InventaryPage/InventaryStorePage/viewProductsBranch'))}/>
          <Route path={`${match.url}inventary/warehouse/add-products/`} component={asyncComponent(() => import('./InventaryPage/InventaryStorePage/addProductBranch'))}/>
          <Route path={`${match.url}inventary/warehouse/create`} component={asyncComponent(() => import('./InventaryPage/InventaryStorePage/addProductBranch'))}/>
          <Route path={`${match.url}branch-office/list`} component={asyncComponent(() => import('./BranchOfficePage'))}/>
          <Route path={`${match.url}inventary/guide/:id`} component={asyncComponent(() => import('./InventaryPage/DispatchPage/GuidePage'))}/>
          <Route path={`${match.url}inventary/dispatch`} component={asyncComponent(() => import('./InventaryPage/DispatchPage/addProductBranch'))}/>
          <Route path={`${match.url}branch-office/create`} component={asyncComponent(() => import('./BranchOfficePage/addBranchOffice'))}/>
          <Route path={`${match.url}branch-office/edit/:id`} component={asyncComponent(() => import('./BranchOfficePage/addBranchOffice'))}/>
          <Route path={`${match.url}reports`} component={asyncComponent(() => import('./reportsPage'))}/>
        </div>
      ) : <Redirect to="/login" /> }
  </div>
);

export default App;
