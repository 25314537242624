import axios from 'axios';
import {message} from "antd";
import { API_URL } from './../../constants/Config';
export const login = async (item) => {

  let res = await axios.post(`${API_URL}/auth/login`, item,{
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
    .then((res) =>{
        return res;
    })
    .catch((err) => {
      console.log("Err", err);

      if(err === "Network Error"){
         message.error('Error de conexión a internet', 3.5);
       }else if(err.response){
         if(err.response.status === 401){
           message.error('Su sesión ha expirado.', 3.5);
           if(localStorage.getItem("user-info")){
             localStorage.removeItem("user-info");
           }
           window.location.href= "/";
         }
       }

       message.error('Error al tratar de iniciar sesión', 3.5);
       return {"status": "404"};
    });

    return res;
}

export const logout = async () => {
    const user_data = JSON.parse(localStorage.getItem('user-info'));
    const api_token = user_data.token_type+" "+user_data.token;

    let res = await axios.get(`${API_URL}/auth/logout`,{
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": api_token
      }
    })
    .then((res) =>{
        return res;
    })
    .catch((err) => {
      console.log("Err", err);

      if(err === "Network Error"){
         message.error('Error de conexión a internet', 3.5);
       }else if(err.response){
         if(err.response.status === 401){
           message.error('Su sesión ha expirado.', 3.5);
           if(localStorage.getItem("user-info")){
             localStorage.removeItem("user-info");
           }
           window.location.href= "/";
         }
       }

       message.error('Error al tratar de cerrar sesión', 3.5);
       return {"status": "404"};
    });

    return res;
}
