import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import { ProfileOutlined, UserOutlined, ShopOutlined, BoxPlotFill, BarcodeOutlined, TagsOutlined, AppstoreOutlined, ClusterOutlined } from '@ant-design/icons';

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { userSession } from '../../helpers/loginHelper';

const { SubMenu } = Menu;

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (<Auxiliary>

        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile/>
            <AppsNavigation/>
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline"
            >

              <Menu.Item key="inventary/list">
                <Link to="/inventary/list"><BarcodeOutlined /><IntlMessages id="sidebar.products"/></Link>
              </Menu.Item>

              <Menu.Item key="inventary/brand/list">
                <Link to="/inventary/brand/list"><TagsOutlined /><IntlMessages id="sidebar.brand"/></Link>
              </Menu.Item>

              <Menu.Item key="branch-office/list">
                <Link to="/branch-office/list"><ShopOutlined /> <IntlMessages id="sidebar.branchs"/></Link>
              </Menu.Item>

              <Menu.Item key="inventary/store/product/list">
                <Link to="/inventary/warehouse/view/61720d95-3837-437e-9fb7-ec4fd036815b"><AppstoreOutlined /> <IntlMessages id="sidebar.warehouses"/></Link>
              </Menu.Item>

              <Menu.Item key="inventary/dispatch">
                <Link to="/inventary/dispatch"><ClusterOutlined /> <IntlMessages id="sidebar.dispatch"/></Link>
              </Menu.Item>

              { userSession() && userSession().user.rol === 'ADMIN' ?
                <Menu.Item key="users/list">
                  <Link to="/users/list"><UserOutlined /> <IntlMessages id="sidebar.userListPage"/></Link>
                </Menu.Item>
              : '' }

              <Menu.Item key="reports">
                <Link to="/reports"><ClusterOutlined /> <IntlMessages id="sidebar.reports"/></Link>
              </Menu.Item>

            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname}
};
export default connect(mapStateToProps)(SidebarContent);

